import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as elementReducer } from 'src/slices/element';
import wizardDataReducer from 'src/slices/classWizard';
import { reducer as dataingestionReducer } from 'src/slices/dataingestion';

import { reducer1 as notificationReducer, reducer2 as machineReducer } from 'src/slices/notification';

const rootReducer = combineReducers({
  wizard: wizardDataReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  element: elementReducer,
  machine: machineReducer,
  dataingestion: dataingestionReducer,
});

export default rootReducer;
