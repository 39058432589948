import axios from 'axios';
import {appSettings} from "../config";


const axiosInstance = axios.create({
    baseURL: appSettings.webApi
    //baseURL:'http://localhost:3000/api/v1/',
});


axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);


axiosInstance.putFile = function(files){
  var formData = new FormData();
    files.forEach((file) => {
      formData.append("file",files);
    });
 
    const options = {
        headers: {'Content-Type': 'multipart/form-data'}
    };
}

export default axiosInstance;
