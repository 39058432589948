import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import CryptoJS from 'crypto-js';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 400,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero = ({ className, EndpointRoot, ...rest }) => {
  const classes = useStyles();
  const { isAuthenticated, isAuthorized, user } = useAuth();

  const getUrl = (user) => {
    if (user.AuditTool) {
      const encryptedEmail = encodeURIComponent(CryptoJS.AES.encrypt(user.email, "audit-tool").toString());
      const accessTkn = localStorage.getItem('accessToken');
      window.location.href = `${user.PlantSubDomain}?tkn=${encryptedEmail}&accsTkn=${accessTkn}`;
      return `${user.PlantSubDomain}?tkn=${encryptedEmail}&accsTkn=${accessTkn}`;
    } else {
      return '/app/reports/dashboard-alternative'
    }
  }


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="overline"
                color="secondary"
              >
                Introducing
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
              >
                TEROTECH 4.0
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  A complete solution to manage PM and AM 
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      WCM
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Compliant
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      AUTOMATIC
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Machine Ledger
                    </Typography>
                  </Grid>
                  {/*  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      300+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Components
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
              <Box mt={3}>
                {/*       <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          
          underline="none"
          variant="body2"
        >
          Dashboard
        </Link> */}
                <Button
                  color="secondary"
                  component={RouterLink}
                  to={user && user.UserPlantsCount == 1 ? getUrl(user) : '/app/plants'}
                  variant="contained"
                  size="large"
                >
                  LOGIN
        </Button>
                {/* <img
                  alt="Javascript"
                  className={classes.technologyIcon}
                  src="/static/images/javascript.svg"
                />
                <img
                  alt="Typescript"
                  className={classes.technologyIcon}
                  src="/static/images/typescript.svg"
                /> */}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/dark-light.png"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
  EndpointRoot: PropTypes.bool
};

export default Hero;
