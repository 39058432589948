import { createSlice } from '@reduxjs/toolkit'
// import Production from 'src/views/mgmt/equipStructure/MachineEditView/ClassWizard/ProductionWizard';

export const wizard = createSlice({
    name: 'wizardData',
    initialState: {
        production: {
            usageTime: -2,
            repercussions: -2,
            backup: -2,
            oee: -2,
            workingPause: -2,
            mtbf: -2,
            total: null
        },
        qualityImpact: {
            complaints: -2,
            defectDetectability: -2,
            reworkCosts: -2,
            total: null
        },
        costImpact: {
            maintenanceBasicConditionsAM: -2,
            maintenanceBasicConditionsPM: -2,
            impactOnFailureMaintenanceCosts: -2,
            energyLosses: -2,
            total: null
        },
        serviceImpact: {
            intermediateBuffers: -2,
            total: null
        },
        ambienceAndSafetyImpact: {
            workSafetyInCaseOfBreakdown: -2,
            noRoutineActions: -2,
            environmentalImpactInCaseOfFailure: -2,
            total: null
        },
        machineAge: {
            yearsOfService: -2,
            total: null
        },
        Classification: {
            total: 0
        },
        setOpen: false
    },
    reducers: {
        setProduction: (state, action) => {
            state.production = action.payload;

            state.production.total = state.production.usageTime +
                        state.production.repercussions +
                        state.production.backup +
                        state.production.oee +
                        state.production.workingPause +
                        state.production.mtbf                       
        },
        setQualityImpact: (state, action) => {
            state.qualityImpact = action.payload;
            state.qualityImpact.total = state.qualityImpact.complaints +
                                        state.qualityImpact.defectDetectability +
                                        state.qualityImpact.reworkCosts
        },
        setCostImpact: (state, action) => {
            state.costImpact = action.payload;
            state.costImpact.total = state.costImpact.energyLosses +
                                    state.costImpact.impactOnFailureMaintenanceCosts +
                                    state.costImpact.maintenanceBasicConditionsAM +
                                    state.costImpact.maintenanceBasicConditionsPM
        },
        setServiceImpact: (state, action) => {
            state.serviceImpact = action.payload;
            state.serviceImpact.total = state.serviceImpact.intermediateBuffers
        },
        setAmbienceAndSafetyImpact: (state, action) => {
            state.ambienceAndSafetyImpact = action.payload;
            state.ambienceAndSafetyImpact.total = state.ambienceAndSafetyImpact.environmentalImpactInCaseOfFailure +
                                                state.ambienceAndSafetyImpact.noRoutineActions +
                                                state.ambienceAndSafetyImpact.workSafetyInCaseOfBreakdown
        },
        setMachineAge: (state, action) => {
            state.machineAge = action.payload;
            state.machineAge.total = state.machineAge.yearsOfService;
            state.Classification.total = state.production.total +
                                        state.qualityImpact.total +
                                        state.costImpact.total +
                                        state.serviceImpact.total +
                                        state.ambienceAndSafetyImpact.total +
                                        state.machineAge.total;
            state.setOpen = false;
        },
        setChangeOpen: (state) => {
            state.setOpen = !state.setOpen;
        }
    },
})

export const { setProduction, setQualityImpact, setCostImpact, setServiceImpact, setAmbienceAndSafetyImpact, setMachineAge, setChangeOpen} = wizard.actions

export default wizard.reducer