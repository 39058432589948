import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
//import Notifications from './Notifications';
import {Search, SvgPanZoom} from './Search';
import Settings from './Settings';
import { useTranslation, withNamespaces } from 'react-i18next';
import i18n from '../../../i18n';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import * as plant from 'src/dataservice/Plant';



const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.TEROTECH ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  },
  logo: {
    height: 50,
    width: 50,
    outlineWidth:50,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }

}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const [plantName, setPlantName] = useState([]);

  const isMountedRef = useIsMountedRef();
  const getPlantInfo = useCallback(async () => {
    try {
      if (isMountedRef.current) {
        const plantInfo = await plant.getInfoUser();
        setPlantName(plantInfo.data.plant.codice + " - " + plantInfo.data.plant.descrizione);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getPlantInfo();
  }, [getPlantInfo]);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo className={classes.logo}/>
          </RouterLink>
        </Hidden>
        
        <h1>{t('Benvenuto sul plant')}: {plantName}</h1>
        
        <Box
          ml={2}
          flexGrow={1}
        />
         {/* <SvgPanZoom />    */}           
        {/*<Search />
        <Contacts /> */}
        {/* <Notifications /> */}
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
