import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useAuth();

  if (isAuthenticated) {
    if (user != null) {
      if (user.endpointRoot) {
        return <Redirect to="/app/plants" />;
      }
      if (user.idprofilo == 1 || user.idprofilo < 4 || user.idprofilo == 6 || user.idprofilo > 7) {
        return <Redirect to="/app/reports/dashboard-alternative" />;
      }
      if (user.idprofilo == 4) {
        return <Redirect to="/app/tablet/homeTablet" />;
      }
      if (user.idprofilo == 5) {
        return <Redirect to="/app/operator/homeOperator" />;
      }
      if (user.idprofilo == 7) {
        return <Redirect to="/forbidden" />;
      }
    }
    else {
      return <Redirect to="/500" />;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
