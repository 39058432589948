/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HomeIcon from '@material-ui/icons/Home';

import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';

import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

import { useTranslation, withNamespaces } from 'react-i18next';

const sections = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Dashboard Alternative',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative'
      }
    ]
  },
  {
    subheader: 'Management',
    
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Plant',
        icon: UsersIcon,
        href: '/app/management/plants',
        items: [
          {
            title: 'List Plants',
            href: '/app/management/plants'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Projects Platform',
        href: '/app/projects',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]
      },
      {
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      },
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      }
    ]
  },
  {
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  {
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          },
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  
  const sections1 =
  /* user.idprofilo == 1 ?   */[
  
     {
      
      items:[
        {
        title: t('Home'),
        icon: HomeIcon,
        href: '/app/reports/dashboard-alternative'

      }]}, 
      {
        subheader:"Plant Layout",
        items:[
          {
            title:"Demo Structure",
            icon:ReceiptIcon,
            href:"/app/plantlayout"
          }
        ]
      },
      {
      subheader: t('Management PM'),
      //icon: HomeIcon,
      items: [
        {
          title: t('StutturaMacchina'), // 'Dashboard',
          icon: AccountTreeIcon,
          //href: '/app/mgmt/'
          href: '/app/mgmt/equipStructure'
        },
        {
          title: t('MetodoSMP'),
          icon: ReceiptIcon,
          href: '/app/mgmt/smp'
        },
        {
          title: t('Manutenzione'),
          icon: ReceiptIcon,
          href: '/app/mgmt/maintenance'
        },
        {
          title: t('CalendarioPM'),
          icon: CalendarTodayIcon,
          href: '/app/mgmt/PMcalendar'
        },
        {
          title: "Calendario Lub",
          icon: CalendarTodayIcon,
          href: '/app/mgmt/PMlubricant'
        },
        {
          title: t('Machine Ledger'),
          icon: BorderAllIcon,
          href: '/app/mgmt/MachineLedger'
        },
        {
          title: t('Cataloghi'),
          icon: FolderIcon,
          items: [
            {
              title: t('Strumenti di Controllo'),
              //  href: '/app/planning/catalog/'
              href: '/app/mgmt/catalogs/controltools',
              

            },
            {
              title: t('Lubrificanti'),
              //  href: '/app/planning/tag/assign'
              href: '/app/mgmt/catalogs/lubfrificants'
            },
            {
              title: t('Materiali Ausiliari'),
              // href: '/app/planning/ewo/assign'
              href: '/app/mgmt/catalogs/auxiliarymaterials'
            },
            {
              title: t('Attrezzi'),
              //  href: '/app/planning/ewo/assign'
              href: '/app/mgmt/catalogs/tools'
            },
          ]
        },
      ]
    },
    {
      subheader: t('Management AM'),
      items: [
        {
          title: t('StutturaMacchina'), // 'Dashboard',
          icon: AccountTreeIcon,
          //href: '/app/mgmt/'
          href: '/app/mgmt/equipStructure'
        },
        {
          title: t('Metodo SOP'),
          icon: ReceiptIcon,
          href: '/app/mgmtAM/sop' 
        },
        {
          title: t('Manutenzione'),
          icon: ReceiptIcon,
          href: '/app/mgmtAM/maintenance',
        },
        {
          title: t('Percorsi'),
          icon: ReceiptIcon,
          href: '/app/mgmtAM/path',
        },
        {
          title: t('CILR'),
          icon: ReceiptIcon,
          href: '/app/mgmtAM/cilr',
        }

       
      ]
    },
    {
      subheader: t('Organizzazione'),
      items: [
        {
          title: t('Definizione Cicli'),
          icon: ReceiptIcon,
          href: '/app/planning/maintenanceCycles',         
        },
        {
          title: t('Gestione Operatori'),
          icon: ReceiptIcon,
          href: '/app/planning/mgmtOperators',         
        },
        {
          title: t('Gestione Squadre'),
          icon: ReceiptIcon,
          href: '/app/planning/mgmtTeams',         
        },
        {
          title: t('Assegnazioni'),
          icon: FolderIcon,
          href: '/app/management/products',
          items: [
            {
              title: t('Ewo'),
              href: '/app/planning/ewo/assign'
            },
            {
              title: t('Tag'),
              href: '/app/planning/tag/assign'
            }
          ]
        },
        {
          title: t('Causa Radice'),
          icon: ReceiptIcon,
          href: '/app/management/RootCauseAnalysis',          
        },
        {
          title: t('CalendarioPM'),
          icon: ReceiptIcon,
          href: '/app/reports/dashboard'
        },
        {
          title: t('Machine Ledger'),
          icon: ReceiptIcon,
          href: '/app/reports/dashboard'
        },
        {
          title: t('CalendarioAM'),
          icon: ReceiptIcon,
          href: '/app/mgmt/lubricant/calendar'
        },
        {
          title: t('Nuovo TAG'),
          icon: ReceiptIcon,
          href: '/app/planning/tag/equipment'
        },
        {
          title: t('Nuova EWO'),
          icon: ReceiptIcon,
          href: '/app/planning/ewo/equipment'
        }
        
      ]
    },
    {
      subheader: t('Esecuzione'),
      items: [
        {
          title: t('Interventi Programmati'),
          href: '/app/execution/homeExec',
          icon: ReceiptIcon         
        },
        {
          title: t('EWO'),
          href: '/app/execution/ewo',
          //href: '/app/planning/ewo/intervention?idewo=1&idmachine=M0160797',
          icon: ReceiptIcon
        },         
        {
          title: t('TAG'),
          href: '/app/execution/tag',
          icon: ReceiptIcon
        },
        {
          title: t('Nuovo TAG'),
          icon: ReceiptIcon,
          href: '/app/planning/tag/equipment'
        },
        {
          title: t('Nuova EWO'),
          icon: ReceiptIcon,
          href: '/app/planning/ewo/equipment'
        },
      ]
    },  
    {
      subheader: t('Configurazione'),
      items: [
        {
          title: t('Librerie impianto'),
          icon: FolderIcon,
          href: '/app/management/products',
          items: [
            {
              title: t('Tipologie Macchine'),
              href: '/app/configuration/equipmentArchives/libMachineTipology' 
            },
            {
              title: t('Tipologie Gruppi'),
              href: '/app/configuration/equipmentArchives/libGroup'
            },
            {
              title: t('Tipologie Sottogruppi'),
              href: '/app/configuration/equipmentArchives/libSubgroup'
            },
            {
              title: t('Tipologie Componenti'),
              href: '/app/configuration/equipmentArchives/libComponent'
            }
          ]
        },
          {
            title: t('Altre Librerie'),
            icon: FolderIcon,
            href: '/app/management/products',
            items: [
              {
                title: t('Costruttori'),
                href: '/app/configuration/othersArchives/libManufacturer'
              },
              {
                title: t('Tipologia Lubrificanti'),
                href: '/app/configuration/othersArchives/libLubrificant'
              },
              {
                title: t('Grandezze fisiche'),
                href: '/app/configuration/othersArchives/libPhisicalQty'
              },
              {
                title: t('Tipologia Materiali'),
                href: '/app/configuration/othersArchives/libMaterials'
              },
              {
                title: t('Tipologia Attrezzi'),
                href: '/app/configuration/othersArchives/libTools'
              },
              {
                title: t('Tipologia Strumenti'),
                href: '/app/configuration/othersArchives/libControlTools'
              }
            ]
          },
        {
          title: t('Libreria Skill'),
          href: '/app/extra/forms',
          icon: FolderIcon,
          items: [
            {
              title: t('Capacita'),
              href: '/app/management/libskill/capacity'
            },
            {
              title: t('Gruppo professionale'),
              href: '/app/management/libskill/professionalgroups'
            },
            {
              title: t('Tipologia Skill'),
              href: '/app/management/libskill/tipology'
            }
          ]
        },
        {
          title: t('Controlli'),
          href: '/app/configuration/controls',
          icon: FolderIcon
        },
        {
          title: "Users",
          href: '/app/configuration/users',
          icon: FolderIcon
        },
        
      ]
    }
   
] /* : [] */;
  
  




 

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>

          
          {sections1.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
 {/*        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box> */}
{/*         <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
