import React, {
  useRef,
  useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const setProfilo = (id) => {
    var prof = "";
    switch (id) {
      case 1:
        prof = "Admin"
        break;
      case 2:
        prof = "AM"
        break;
      case 3:
        prof = "PM"
        break;
      case 4:
        prof = "Man"
        break;
      case 5:
        prof = "Requestor"
        break;
      case 6:
        prof = "Guest"
        break;
      case 7:
        prof = "Disabled"
        break;
      case 8:
        prof = "Super Admin"

      default:
        break;
    }
    return prof;
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user.avatar}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {user.name} ({ setProfilo(user.idprofilo) })
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
      {/*   <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem> */}
        {user.UserPlantsCount !== 1 &&  (
          <MenuItem
            component={RouterLink}
            to="/app/plant"
          >
            Change Plant
          </MenuItem>
        )}

     {/*    <MenuItem
          component={RouterLink}
          to="/app/account"
        >
          Account
        </MenuItem> */}
        <MenuItem
          component={RouterLink}
          to="/docs"
          target="_blank"
        >
          Documentation
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
