import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/terotechDataservice';
import objFromArray from 'src/utils/objFromArray';
import { highlightAllUnder } from 'prismjs';
import { getGridPageCount } from '@material-ui/data-grid';
const initialState = {
  isLoaded: false,
  lists: {
    byId: {},
    allIds: []
  },
  cards: {
    byId: {},
    allIds: []
  },
  members: {
    byId: {},
    allIds: []
  }
};
const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    getBoard(state, action) {
      const { board } = action.payload;
      state.lists.byId = objFromArray(board.lists);
      state.lists.allIds = Object.keys(state.lists.byId);
      //state.lists.byCode = objFromArray(board.lists.byCode);
      state.cards.byId = objFromArray(board.cards);
      state.cards.allIds = Object.keys(state.cards.byId);
      state.members.byId = objFromArray(board.members);
      state.members.allIds = Object.keys(state.members.byId);
      state.isLoaded = true;
    },
    createList(state, action) {
      const { list } = action.payload;
      state.lists.byId[list.id] = list;
      state.lists.allIds.push(list.id);
    },
    updateList(state, action) {
      const { list } = action.payload;
      state.lists.byId[list.id] = list;
    },
    clearList(state, action) {
      const { listId } = action.payload;
      const { cardIds } = state.lists.byId[listId];
      state.lists.byId[listId].cardIds = [];
      state.cards.byId = _.omit(state.cards.byId, cardIds);
      _.pull(state.cards.allIds, ...cardIds);
    },
    deleteList(state, action) {
      const { listId } = action.payload;
      state.lists.byId = _.omit(state.lists.byId, listId);
      _.pull(state.lists.allIds, listId);
    },
    createCard(state, action) {
      const { card } = action.payload;
      state.cards.byId[card.id] = card;
      state.cards.allIds.push(card.id);
      state.lists.byId[card.listId].cardIds.push(card.id);
    },
    updateCard(state, action) {
      const { card } = action.payload;
      _.merge(state.cards.byId[card.id], card);
    },
    moveCard(state, action) {
      const { cardId, position, listId } = action.payload;
      const { listId: sourceListId } = state.cards.byId[cardId];
      _.pull(state.lists.byId[sourceListId].cardIds, cardId);
      if (listId) {
        state.cards.byId[cardId].listId = listId;
        state.lists.byId[listId].cardIds.splice(position, 0, cardId);
      } else {
        state.lists.byId[sourceListId].cardIds.splice(position, 0, cardId);
      }
    },
    deleteCard(state, action) {
      const { cardId } = action.payload;
      const { listId } = state.cards.byId[cardId];
      state.cards.byId = _.omit(state.cards.byId, cardId);
      _.pull(state.cards.allIds, cardId);
      _.pull(state.lists.byId[listId].cardIds, cardId);
    },
    addComment(state, action) {
      const { comment } = action.payload;
      const card = state.cards.byId[comment.cardId];
      card.comments.push(comment);
    },
    addChecklist(state, action) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists.push(checklist);
    },
    updateChecklist(state, action) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.map(card.checklists, (_checklist) => {
        if (_checklist.id === checklist.id) {
          return checklist;
        }
        return _checklist;
      });
    },
    deleteChecklist(state, action) {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.reject(card.checklists, { id: checklistId });
    },
    addCheckItem(state, action) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      _.assign(card, {
        checklists: _.map(card.checklists, (checklist) => {
          if (checklist.id === checklistId) {
            _.assign(checklist, {
              checkItems: [...checklist.checkItems, checkItem]
            });
          }
          return checklist;
        })
      });
    },
    updateCheckItem(state, action) {
      const {
        cardId,
        checklistId,
        checkItem
      } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.map(checklist.checkItems, (_checkItem) => {
              if (_checkItem.id === checkItem.id) {
                return checkItem;
              }
              return _checkItem;
            })
          });
        }
        return checklist;
      });
    },
    deleteCheckItem(state, action) {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.reject(checklist.checkItems, { id: checkItemId })
          });
        }
        return checklist;
      });
    }
  }
});
export const reducer = slice.reducer;
const calc_height = (n) => {
  switch (n) {
    case 1:
      return n * 1500;
    case 2:
      return n * 375;
    case 3:
      return n * 175;
    case 4:
      return n * 95;
    default:
      return n * 50;
  }
};
export const getBoard = (machinecode,closeLoading) => async (dispatch) => {
  
  const list_names = [
    'lista_macchina_MC',
    'lista_gruppo_GR',
    'lista_sottogruppo_SG',
    'lista_componente_CP'
  ];
  const card_names = [
    'card_MC',
    'card_GR',
    'card_SG',
    'card_CP'
  ];

  //let response_api = await axios.get('/machinestructure/items?Filter=code=%22' + machinecode + '%22&orderBy=idFather,level desc&pageSize=100');
  let response_api = await axios.get('/machinestructure/getstructure?machineCode=' + machinecode);
  let mach = response_api.data;
  let response_kanban = { data: { board: { lists: [], cards: [], members: [], } } };
  
  const fixCardCount = (n) => {
    return n > 0 ? n : 1;
  };

  const countMachCards = (mch) => {
    var count=0;
    mch.groups.forEach(g => {
       if (g.subGroups.length > 0) {
        g.subGroups.forEach(s => {
          if (s.components.length > 0) {
            count += s.components.length;
          } else {
            count++;
          }
        });
       } else {
        count++;
       }
      });
    return count;
  };

  const countGroupCards = (g) => {
    var count=0;
    if (g.subGroups.length > 0) {
      g.subGroups.forEach(s => {
        if (s.components.length > 0) {
          count += s.components.length;
        } else {
          count++;
        }
      });
    } else {
      count++;
    }
    return count;
  };

  const countSubGroupCards = (s) => {
    var count=s.components.length;
    return count > 0 ? count : 1;
  };
  
  response_kanban.data.board.lists.push({
    id: list_names[0] + mach.id,
    name: mach.descr,
    numComponents: countMachCards(mach),
    he1: (150 * countMachCards(mach)) || 150,
    elementId: mach.id,
    machineId: mach.idMacchina,
    level: 0,
    cardIds:
      [
        card_names[0] + mach.id
      ]
  });

  mach.groups.forEach(gr => {
    response_kanban.data.board.lists.push({
      id: list_names[1] + gr.id,
      name: gr.descr,
      numComponents: countGroupCards(gr),
      he1: (150 * countGroupCards(gr)) || 150,
      elementId: gr.id,
      spare: gr.spare,
      machineId: gr.idMacchina,
      level: 1,
      cardIds:
        [
          card_names[1] + gr.id
        ]
    });
    if (gr.subGroups.length > 0) {
      gr.subGroups.forEach(sg => {
        response_kanban.data.board.lists.push({
          id: list_names[2] + sg.id,
          name: sg.descr,
          numComponents: countSubGroupCards(sg),
          he1: (150 * countSubGroupCards(sg)) || 150,
          elementId: sg.id,
          spare: sg.spare,
          machineId: sg.idMacchina,
          level: 2,
          cardIds:
            [
              card_names[2] + sg.id
            ]
        });

        if (sg.components.length > 0) {
          sg.components.forEach(cp => {
            response_kanban.data.board.lists.push({
              id: list_names[3] + cp.id,
              name: cp.descr,
              numComponents: 0,
              he1: (150),  
              elementId: cp.id,
              spare: cp.spare,
              machineId: cp.idMacchina,
              level: 3,
              cardIds:
                [
                  card_names[3] + cp.id
                ]
            });
          });
        } else {
          response_kanban.data.board.lists.push({
            id: list_names[3] + "empty_cp",
            name: "empty_cp",
            numComponents: 0,
            he1: 150,
            elementId: 0,
            spare: 0,
            machineId: 0,
            level: 3,
            cardIds:
              [
                card_names[3] + "empty_cp"
              ]
          });
        }
      });
    } else {
      response_kanban.data.board.lists.push({
        id: list_names[2] + "empty_sg",
        name: "empty_sg",
        numComponents: 1,
        he1: 150,
        elementId: 0,
        spare: 0,
        machineId: 0,
        level: 2,
        cardIds:
          [
            card_names[2] + "empty_sg"
          ]
      });
      response_kanban.data.board.lists.push({
        id: list_names[3] + "empty_cp",
        name: "empty_cp",
        numComponents: 0,
        he1: 150,
        elementId: 0,
        spare: 0,
        machineId: 0,
        level: 3,
        cardIds:
          [
            card_names[3] + "empty_cp"
          ]
      });
    }
  });

  response_kanban.data.board.cards = []

  let mcCard = {
    listId: list_names[0] + mach.id,
    id: card_names[0] + mach.id,
    cover: mach.image,
    description: mach.descr,
    name: mach.child,
    spare: mach.spare,
    path: mach.path,
    level: 0,
    due: null,
    isSubscribed: false,
    attachments: [],
    checklists: [],
    comments: [],
    memberIds: []
  }
  response_kanban.data.board.cards.push(mcCard);
  
  mach.groups.forEach(gr => {
    response_kanban.data.board.cards.push({
      listId: list_names[1] + gr.id,
      id: card_names[1] + gr.id,
      cover: gr.image,
      description: gr.descr,
      name: gr.child,
      spare: gr.spare,
      path: gr.path,
      level: 1,
      due: null,
      isSubscribed: false,
      attachments: [],
      checklists: [],
      comments: [],
      memberIds: []
    });
    if (gr.subGroups.length > 0) {
      gr.subGroups.forEach(sg => {
        response_kanban.data.board.cards.push({
          listId: list_names[2] + sg.id,
          id: card_names[2] + sg.id,
          cover: sg.image,
          description: sg.descr,
          name: sg.child,
          spare: sg.spare,
          path: sg.path,
          level: 2,
          due: null,
          isSubscribed: false,
          attachments: [],
          checklists: [],
          comments: [],
          memberIds: []
        });
        if (sg.components.length > 0) {
          sg.components.forEach(cp => {
            response_kanban.data.board.cards.push({
              listId: list_names[3] + cp.id,
              id: card_names[3] + cp.id,
              cover: cp.image,
              description: cp.descr,
              name: cp.child,
              spare: cp.spare,
              path: cp.path,
              level: 3,
              due: null,
              isSubscribed: false,
              attachments: [],
              checklists: [],
              comments: [],
              memberIds: []
            });
          });
        } else {
          response_kanban.data.board.cards.push({
            listId: list_names[3] + "empty_cp",
            id: card_names[3] + "empty_cp",
            cover: "",
            description: "empty_cp",
            name: "empty_cp",
            spare: "",
            path: "",
            level: 3,
            due: null,
            isSubscribed: false,
            attachments: [],
            checklists: [],
            comments: [],
            memberIds: []
          });
        }
      });
    } else {
      response_kanban.data.board.cards.push({
        listId: list_names[2] + "empty_sg",
        id: card_names[2] + "empty_sg",
        cover: "",
        description: "empty_sg",
        name: "empty_sg",
        spare: "",
        path: "",
        level: 2,
        due: null,
        isSubscribed: false,
        attachments: [],
        checklists: [],
        comments: [],
        memberIds: []
      });

      response_kanban.data.board.cards.push({
        listId: list_names[3] + "empty_cp",
        id: card_names[3] + "empty_cp",
        cover: "",
        description: "empty_cp",
        name: "empty_cp",
        spare: "",
        path: "",
        level: 3,
        due: null,
        isSubscribed: false,
        attachments: [],
        checklists: [],
        comments: [],
        memberIds: []
      });
    }
  });
  
  response_kanban.data.board.members = [
    {
      id: 'fornitore_1',
      avatar: '/static/images/avatars/avatar_1.png',
      name: 'Test - Fornitore 1',
    },
    {
      id: 'fornitore_2',
      avatar: '/static/images/avatars/avatar_2.png',
      name: 'Test - Fornitore 2',
    },
    {
      id: 'fornitore_3',
      avatar: '/static/images/avatars/avatar_3.png',
      name: 'Fornitore 3'
    },
    {
      id: 'fornitore_4',
      avatar: '/static/images/avatars/avatar_4.png',
      name: 'Test - Fornitore 4'
    },
    {
      id: 'fornitore_5',
      avatar: '/static/images/avatars/avatar_5.png',
      name: 'Fornitore 5'
    },
    {
      id: 'fornitore_6',
      avatar: '/static/images/avatars/avatar_6.png',
      name: 'Fornitore 6'
    }
  ];

  console.log("chiusura loading")
  closeLoading(false);
  console.log('Response api');
  console.log(response_api);
  console.log('Response kanban');
  console.log(response_kanban);

  dispatch(slice.actions.getBoard(response_kanban.data));
};

export const createList = (name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/lists/new', {
    name
  });
  dispatch(slice.actions.createList(response.data));
};

export const updateList = (listId, update) => async (dispatch) => {
  const response = await axios.post('/api/kanban/list/update', {
    listId,
    update
  });
  dispatch(slice.actions.updateList(response.data));
};
export const clearList = (listId) => async (dispatch) => {
  await axios.post('/api/kanban/lists/clear', {
    listId
  });
  dispatch(slice.actions.clearList({ listId }));
};
export const deleteList = (listId) => async (dispatch) => {

  await axios.delete('/machinestructure/items/' + listId);
  dispatch(slice.actions.deleteList({ listId }));
  
};
export const createCard = (listId, name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/cards/new', {
    listId,
    name
  });
  dispatch(slice.actions.createCard(response.data));
};
export const updateCard = (cardId, update) => async (dispatch) => {
  const response = await axios.post('/api/kanban/cards/update', {
    cardId,
    update
  });
  dispatch(slice.actions.updateCard(response.data));
};
export const moveCard = (cardId, position, listId) => async (dispatch) => {
  await axios.post('/api/kanban/cards/move', {
    cardId,
    position,
    listId
  });
  dispatch(slice.actions.moveCard({
    cardId,
    position,
    listId
  }));
};
export const deleteCard = (cardId) => async (dispatch) => {
  await axios.post('/api/kanban/cards/remove', {
    cardId
  });
  dispatch(slice.actions.deleteCard({ cardId }));
};
export const addComment = (cardId, message) => async (dispatch) => {
  const response = await axios.post('/api/kanban/comments/new', {
    cardId,
    message
  });
  dispatch(slice.actions.addComment(response.data));
};
export const addChecklist = (cardId, name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checklists/new', {
    cardId,
    name
  });
  const { checklist } = response.data;
  dispatch(slice.actions.addChecklist({
    cardId,
    checklist
  }));
};
export const updateChecklist = (cardId, checklistId, update) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checklists/update', {
    cardId,
    checklistId,
    update
  });
  const { checklist } = response.data;
  dispatch(slice.actions.updateChecklist({
    cardId,
    checklist
  }));
};
export const deleteChecklist = (cardId, checklistId) => async (dispatch) => {
  await axios.post('/api/kanban/checklists/remove', {
    cardId,
    checklistId
  });
  dispatch(slice.actions.deleteChecklist({
    cardId,
    checklistId
  }));
};
export const addCheckItem = (cardId, checklistId, name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checkitems/new', {
    cardId,
    checklistId,
    name
  });
  const { checkItem } = response.data;
  dispatch(slice.actions.addCheckItem({
    cardId,
    checklistId,
    checkItem
  }));
};
export const updateCheckItem = (cardId, checklistId, checkItemId, update) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checkitems/update', {
    cardId,
    checklistId,
    checkItemId,
    update
  });
  const { checkItem } = response.data;
  dispatch(slice.actions.updateCheckItem({
    cardId,
    checklistId,
    checkItem
  }));
};
export const deleteCheckItem = (cardId, checklistId, checkItemId) => async (dispatch) => {
  await axios.post('/api/kanban/checkitems/remove', {
    cardId,
    checklistId,
    checkItemId
  });
  dispatch(slice.actions.deleteCheckItem({
    cardId,
    checklistId,
    checkItemId
  }));
};
export default slice;