/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Database as DbIcon,
  Package as PackageIcon
} from 'react-feather';
import NavItem from './NavItem';

const items = [
  {
    title: 'Terotech',
    href: '/docs/welcome'
  },
  {
    title: 'Getting Started',
    icon: FolderIcon,
    items: [
      {
        title: 'Login',
        href: '/docs/global/login'
      },
      {
        title: 'User Roles',
        href: '/docs/global/user'
      },
      {
        title: 'Global functionalities',
        href: '/docs/global/functionalities'
      },
      
    ]
  },
  {
    title: 'Plant Layout',
    href: '/docs/PlantLayout'
  },
  {
    title: 'PM Management',
    icon: FolderIcon,
    items: [
      {
        title: 'Overview/Workflow',
        href: '/docs/PMmgmt/workflow'
      },
      {
        title: 'Equipment Structure',
        href: '/docs/PMmgmt/structure'
      },
      {
        title: 'SMP Method',
        href: '/docs/PMmgmt/smp'
      },
      {
        title: 'Maintenance Strategy',
        href: '/docs/PMmgmt/strategy'
      },
      {
        title: 'Machine Ledger',
        href: '/docs/PMmgmt/ml'
      }
    ]
  },
  {
    title: 'Planning',
    icon: FolderIcon,
    items: [
      {
        title: 'Overview/Workflow',
        href: '/docs/planning/workflow'
      },
      {
        title: 'Maint. Cycles',
        href: '/docs/planning/routing'
      },
      {
        title: 'Maint.  Tech Mgmt',
        href: '/docs/planning/technician'
      },
      {
        title: 'Team Mgmt',
        href: '/docs/planning/teams'
      },
      {
        title: 'EWO',
        icon: FolderIcon,
         items: [
          {
            title: 'New EWO',
            href: '/docs/planning/newewo'
          },
          {
            title: 'Assign EWO',
            href: '/docs/planning/assignewo'
          }


         ]
      },
      {
        title: 'TAG',
        icon: FolderIcon,
         items: [
          {
            title: 'New TAG',
            href: '/app/ewo/new'
          },
          {
            title: 'Assign TAG',
            href: '/app/ewo/assign'
          }


         ]
      },

    ]
  },
  {
    title: 'Maint. Tech Reporting',
    icon: FolderIcon,
    items: [
    /*   {
        title: 'Overview/Workflow',
        href: '/docs/reporting/workflow'
      }, */
      {
        title: 'Planned Maintenance',
        href: '/docs/reporting/pm'
      },
      {
        title: 'EWO',
        href: '/docs/reporting/ewo'
      },
      {
        title: 'TAG',
        href: '/docs/reporting/tag'
      }

    ]
  },
  {
    title: 'Configuration',
    icon: FolderIcon,
    items: [
      {
        title: 'Overview/Workflow',
        href: '/docs/planning/workflow'
      },
      {
        title: 'Equipment Archives',
        href: '/docs/planning/structure'
      },
      {
        title: 'Others Archives',
        href: '/docs/planning/mainttech'
      },
      {
        title: 'Skills Archives',
        href: '/docs/planning/mainttech'
      }

    ]
  },
  
  {
    title: 'Data Import',
    icon: FolderIcon,
    items: [
     
    ]
  },
];

function renderNavItems({ items, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  item,
  depth = 0
}) {
  if (item.items) {
    acc.push(
      <NavItem
        depth={depth}
        key={item.href}
        title={item.title}
      >
        {renderNavItems({
          items: item.items,
          depth: depth + 1
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        key={item.href}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Hidden lgUp>
        <Box p={2}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
      </Hidden>
      <Box p={2}>
        {renderNavItems({ items })}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
