import axios from 'src/utils/terotechDataservice';

const upload = (file, onUploadProgress) => {
    let formData = new FormData();

    formData.append("file", file);

    var res = axios.post("/dataingestion/files", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
    return res;
};

const getFiles = () => {
    return axios.get("/dataingestion/files");
};

const deleteFile = (docid) => {
    return axios.delete("/dataingestion/files/" + docid);
};

const getFile = (docid) => {
    return axios.get("/dataingestion/file/" + docid);
};

const getMappers = () => {
    return axios.get("/dataingestion/mappers");
};

const getMappingResult = (id) => {
    return axios.get("/dataingestion/mapping/" + id);
};

const getIntermediateData = (id) => {
    return axios.get("/dataingestion/checkdataintegrity/" + id);
};

const saveIntermediateData = (data) => {
    var res = axios.post("/dataingestion/importdata", data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
}

const saveMappingInfo = (mappingInfo) => {
    var res = axios.post("/dataingestion/mapping", mappingInfo, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
};

export default {
    upload,
    getFiles,
    getFile,
    deleteFile,
    getMappers,
    getMappingResult,
    saveMappingInfo,
    getIntermediateData,
    saveIntermediateData
};