import React, {
  createContext,
  useEffect,
  useState
} from 'react';
import _ from 'lodash';
import { THEMES, LANG, GLOB} from 'src/constants';
import { Language } from '@material-ui/icons';
import * as dataSetting from 'src/dataservice/dataSetting';

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.TEROTECH,
  language: LANG.it,
  globalization: GLOB.it
};

export const restoreSettings = () => {

  let settings = null;

  try {
    const storedData = window.localStorage.getItem('settings');
    console.log('storedData', storedData)
    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        direction: "ltr",
        responsiveFontSizes: "true",
        theme: "TEROTECH",
        language: "en",
        globalization: "en"
      };
    }
  } catch (err) {
    console.error(err);
  }

  return settings;
};

export const storeSettings = (settings) => {

  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext({

  settings: defaultSettings,
  saveSettings: () => { }
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);
  
  const handleSaveSettings = (update = {}) => {
    
    const mergedSettings = _.merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
    
  };

  useEffect(() => {
    
    
    //let user = window.localStorage.getItem('username');
    //if(!user){
      const restoredSettings = restoreSettings();

      if (restoredSettings) {
        setCurrentSettings(restoredSettings);
      }
    //}
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
