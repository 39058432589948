import { createSlice } from '@reduxjs/toolkit';
import DataIngestionService from 'src/dataservice/dataIngestion';

const initialState = {
    wizState : -1,
    filesInfo: [],
    actualFile: undefined,
    currentMappingOperation: 0,
    intermediateData: undefined,
    currentMapper: undefined,
    currentMapperValue: [],
    mapperInfos: [],
    selectedTab: '',
    currentMapperInfo: -1,
}

const slice = createSlice({
    name: 'dataingestion',
    initialState,
    reducers: {
        setWizState(state, action) {
            const wzState = action.payload;
            state.wizState = wzState;
        },
        setFilesInfo(state, action) {
            const infos = action.payload;
            state.filesInfo = infos;
        },setActualFile(state, action) {
            const file = action.payload;
            const clone = JSON.parse(JSON.stringify(file));

            clone.metadata.Tables.forEach(t => t.Columns = t.Columns.map(c => ({
                    ...c,
                    Table: { Index: t.Index, Name: t.Name }
                })))

            state.actualFile = clone;
        },
        setCurrentMappingOperation(state, action) {
            const id = action.payload;
            state.currentMappingOperation = id;
        },
        setIntermediateData(state, action) {
            const data = action.payload;
            state.intermediateData = data;
        },
        setCurrentMapper(state, action) {
            const currMapper = action.payload;
            
            if (currMapper) {
                state.currentMapper = currMapper;
                state.selectedTab = currMapper.data.Tables[0].Name;
                state.currentMapperInfo = state.mapperInfos.findIndex(el => el.id === currMapper.id);
            } else {
                state.currentMapper = undefined;
                state.selectedTab = '';
                state.currentMapperInfo = -1;
            }
        },
        setMapperInfos(state, action) {
            const infos = action.payload;
            const clone = JSON.parse(JSON.stringify(infos));
            clone.forEach((item) => {
                item.data.Tables.forEach(t => t.Columns = t.Columns.map(c => ({
                    ...c,
                    Table: { Index: t.Index, Name: t.Name }
                })))
            });

            if (clone) {
                state.mapperInfos = clone;
            } else {
                state.mapperInfos = [];
            }

            if (state.mapperInfos && state.mapperInfos.length > 0) {
                state.currentMapper = state.mapperInfos[0];
                state.selectedTab = state.currentMapper.data.Tables[0].Name;
            } else {
                state.currentMapper = undefined;
                state.selectedTab = '';
            }
        },
        setSelectedTab(state, action) {
            const infos = action.payload;
            state.selectedTab = infos;
        },
        setCurrentMapperValue(state, action) {
            const curVal = action.payload;
            state.currentMapperValue = curVal;
        },
        setCurrentMapperInfo(state, action) {
            const curVal = action.payload;
            state.currentMapperInfo = curVal;
        },
        reset(state, action) {
            state.wizState = -1;
            state.filesInfo = [];
            state.actualFile =  undefined;
            state.currentMappingOperation= 0;
            state.intermediateData= undefined;
            state.currentMapper= undefined;
            state.currentMapperValue= [];
            state.mapperInfos= [];
            state.selectedTab= '';
            state.currentMapperInfo= -1;
        }
    }
});

export const reducer = slice.reducer;

export const setWizState = (wstate) => (dispatch) => {
    dispatch(slice.actions.setWizState(wstate));
};

export const setFilesInfo = () => (dispatch) => {
    DataIngestionService.getFiles().then((response) => {
        dispatch(slice.actions.setFilesInfo(response.data));
    });
};

export const setActualFile = (currFile) => (dispatch) => {
    DataIngestionService.getFile(currFile).then((response) => {
        dispatch(slice.actions.setActualFile(response.data));
    });
};

export const setCurrentMappingOperation = (id) => (dispatch) => {
    dispatch(slice.actions.setCurrentMappingOperation(id));
};

export const setMappingOperation = (id) => (dispatch) => {
    dispatch(slice.actions.setCurrentMappingOperation(id));
};

export const setIntermediateData = (data) => (dispatch) => {
    dispatch(slice.actions.setIntermediateData(data));
};

export const clearIntermediateData = () => (dispatch) => {
    dispatch(slice.actions.setIntermediateData(undefined));
};

export const updateIntermediateData = (data) => (dispatch) => {
    dispatch(slice.actions.setIntermediateData(data));
};

export const setCurrentMapper = (curMapper) => (dispatch) => {
    dispatch(slice.actions.setCurrentMapper(curMapper));
};

export const setSelectedTab = (selectedTab) => (dispatch) => {
    dispatch(slice.actions.setSelectedTab(selectedTab));
};

export const setMapperInfos = () => (dispatch) => {
    DataIngestionService.getMappers().then((response) => {
        dispatch(slice.actions.setMapperInfos(response.data));
    });
};

export const setCurrentMapperValue = (curVal) => (dispatch) => {
    dispatch(slice.actions.setCurrentMapperValue(curVal));
};

export const setCurrentMapperInfo = (curVal) => (dispatch) => {
    dispatch(slice.actions.setCurrentMapperInfo(curVal));
};

export const reset = () => (dispatch) => {
    dispatch(slice.actions.reset());
};

export default slice;