import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18next from 'i18next';

import translationEN from 'src/translations/en/translation.json';
import translationIT from 'src/translations/it/translation.json';
import translationFR from 'src/translations/fr/translation.json';
import commonEN from 'src/translations/en/common.json';
import commonIT from 'src/translations/it/common.json';

import LanguageDetector from 'i18next-browser-languagedetector';

//i18next.use(LanguageDetector).init(i18nextOptions);





const detectionOptions = {
    order: ['localStorage','path', 'cookie', 'navigator',  'subdomain', 'queryString', 'htmlTag'],
    lookupLocalStorage: 'i18nextLng'
   // lookupLocalStorage: 'settings{language}'

}

const resources = {
  en: { translation: translationEN //, commonEN

  },
  it: {
    translation: translationIT//, commonIT

  },
  fr: {
    translation: translationFR//, commonFR
  }
};



  i18next
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    //lng: "en",
    detection: detectionOptions,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
 export default i18next;

 //i18n.changeLanguage()
