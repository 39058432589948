import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';


const AuthGuard = ({ children }) => {
  const { isAuthenticated, isAuthorized, user } = useAuth();
  if (!isAuthenticated) {
    return <Redirect to="/login" />;

  } else {//is authenticated
    if (!isAuthorized) {
      if (user == null) {
        return <Redirect to="/500" />;
      }
      else {
        return <Redirect to="/401" />;
      }

    }
  }
  //if (user.UserPlantsCount == 1) {
  //  return <Redirect to="/app/reports/dashboard-alternative"/>
  //}
  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
