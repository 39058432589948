import React, { useState } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { INITIAL_VALUE, ReactSVGPanZoom, UncontrolledReactSVGPanZoom, TOOL_NONE } from 'react-svg-pan-zoom';
import {ReactSvgPanZoomLoader} from 'react-svg-pan-zoom-loader'

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';

import {
  Search as SearchIcon,
  Airplay,
  XCircle as XIcon
} from 'react-feather';

import axios from 'src/utils/axios';

const useStyles = makeStyles(() => ({
  drawer: {
    width: '100%',
    maxWidth: '100%'
  }
}));

const Search = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  
  const tool = TOOL_NONE;  
  const fill_machine = "#F2F2F2";
  const fill_block = "#F2F2F2";
  const fill_operation = "#F2F2F2";
  
  const Viewer = null;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      const response = await axios.get('/api/search');

      setResults(response.data.results);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h4"
                color="textPrimary"
              >
                Search
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setValue(event.target.value)}
                placeholder="Search people &amp; places"
                value={value}
                variant="outlined"
              />
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Box mt={4}>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {results.map((result, i) => (
                    <Box
                      key={i}
                      mb={2}
                    >
                      <Link
                        variant="h4"
                        color="textPrimary"
                        component={RouterLink}
                        to="/app"
                      >
                        {result.title}
                      </Link>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                      >
                        {result.description}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
        
      </Drawer>
    </>
  );
};

// export default Search;
// const svgclasses = useStyles();

class SvgPanZoom extends React.PureComponent {

  state = {
    isOpen: false,
    redirect: null,
    tool: TOOL_NONE, 
    value: INITIAL_VALUE,
    fill_machine: "#F2F2F2",
    fill_block: "#F2F2F2",
    fill_operation: "#F2F2F2",
    areListenersDefined: false,
    svg_file: "/static/fiancata-dx.svg"
  };
  
  Viewer = null;

  componentDidMount() {
    console.log('SVG component mounted - operation and machine click event OK');
  }
  
  
  componentDidUpdate() {
    var machines = document.querySelectorAll('.st1');
    var avpe     = document.getElementById('M0160797');
    if (true) {
      this.setState({
        redirect: null,
        areListenersDefined: true}, this.setClickListeners); // function() {
    }
  };
  
  
  setClickListeners() {
    var machines = document.querySelectorAll('.st1');
    var avpe     = document.getElementById('M0160797');
    for (let i = 0; i < machines.length; i++) {
      if (true) {
        var old_element = document.getElementById(machines[i].id);
        var new_element = old_element.cloneNode(true);
        old_element.parentNode.replaceChild(new_element, old_element);
        new_element.addEventListener("click", (event) => {
          this.setState({
              isOpen: false
              
             // redirect: "/app/mgmt/equipStructure/" + event.target.getAttribute('data-codice') 
            }
          );
          localStorage.setItem('machine', event.target.getAttribute('data-codice') );
          localStorage.setItem('idmachine', event.target.getAttribute('data-id') );
        });
      }
    }
  };

  changeTool(nextTool) {
    this.setState({tool: nextTool})
  }

  changeValue(nextValue) {
    this.setState({value: nextValue}, this.setClickListeners);
  };

  fitToViewer() {
    this.Viewer.fitToViewer()
  }

  fitSelection() {
    this.Viewer.fitSelection(40, 40, 200, 200)
  }

  zoomOnViewerCenter() {
    this.Viewer.zoomOnViewerCenter(1.1)
  }
  
  handleOpenSVG() {
    // setOpen(true);
    // this.setState({isOpen: true});
  };

  handleCloseSVG() {
    // setOpen(false);
    // this.setState({isOpen: false});
  };

  handleBadge = _ => { // () {
    this.CreateBadgeInSVG();
  };
  
  CreateBadgeInSVG  = _ => { // () {
    var xmlns = "http://www.w3.org/2000/svg";
    var svgElem = document.createElementNS(xmlns, "circle");
    svgElem.setAttributeNS(null, "cx", "93.5");
    svgElem.setAttributeNS(null, "cy", "80.4");
    svgElem.setAttributeNS(null, "r", "4");
    svgElem.setAttributeNS(null, "stroke", "black");
    svgElem.setAttributeNS(null, "stroke-width", "1");
    svgElem.setAttributeNS(null, "fill", "red");
    svgElem.style.display = "block";
    var svgContainer = document.getElementById("g2");
    svgContainer.appendChild(svgElem);

    var svgNS = "http://www.w3.org/2000/svg";
    var newText = document.createElementNS(svgNS,"text");
    newText.setAttributeNS(null,"x","91.2"); 
    newText.setAttributeNS(null,"y","82.8");
    newText.setAttributeNS(null,"font-size","8");
    newText.setAttributeNS(null,"font-weight","bold");
    var textNode = document.createTextNode("4");
    newText.appendChild(textNode);
    svgContainer.appendChild(newText);
  };

  render() {
    let { isOpen } = this.state;
    
    const handleOpen = () => {
      this.setState({isOpen: true});
    };
    const handleClose = () => {
      this.setState({isOpen: false});
    };
    
    return (
      <>
      <Tooltip title="SVG">
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SvgIcon fontSize="small">
            <Airplay />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        style={{ width: '100%'}}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <div style={{display: "inline-block", marginBottom: "10px", background: "#fff", padding: "60px"}}>
          <button
              className="btn btn-default"
              onClick={this.handleBadge}>Crea badge macchina 17SX</button>
        </div>
        <ReactSvgPanZoomLoader src={this.state.svg_file} render= {(content) => (
          <ReactSVGPanZoom
            key={'svg_' + new Date().getTime()}
            id={'svg_' + new Date().getTime()}
            width={1000}
            height={800}
            ref={Viewer => this.Viewer = Viewer}
            tool={this.state.tool}
            detectAutoPan={false}
            onChangeTool={tool => this.changeTool(tool)}
            value={this.state.value}
            onChangeValue={value => this.changeValue(value)}
          >
          <svg width={1000} height={800}>
              {content}
            </svg>
        </ReactSVGPanZoom>
        )}/>
      </Drawer>      
      { this.state.redirect ? 
            <Redirect to={this.state.redirect} />
      : null}
    </>
    );
  }
};

export {
  Search,
  SvgPanZoom
}

