export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  TEROTECH: 'TEROTECH'
};

export const LANG = {
  en: 'en',
  it: 'it',
  fr: 'fr'
};

export const GLOB = {
  en: 'en',
  it: 'it'
};



export const DATE_OPTIONS = { year: 'numeric', month: '2-digit', day: '2-digit', hourCycle: 'h24', hour: '2-digit', minute: '2-digit' };
