import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/terotechDataservice';
import moment from 'moment';
 
const initialState = {
  element: [],
  status: 'Status iniziale',
  element: 
  {
    "id":0,
    "idMachine": 0,
    "idFather": 0,
    "idtipology": 0,
    "tiporec": "",
    "spare": 0,
    "code": "",
    "codeSap": "",
    "description": "",
    "idsupplier": 0,
    "flagAm": 0,
    "dateSetup": "",
    "classSpare":"" ,
    "codeSpare":"" , //campo aggiuntivo per la gestione codice ricambio
    "price": 0,
    "nrBd": 0,
    "minBd": 0,
    "mtbfT": "",
    "mtbfR": "",
    "mttrT": "",
    "mttrR": "",
    "position": "",
    "stockMin": 0,
    "stock": 0,
    "dimRic": "",
    "dimCont": "",
    "stacked": 0,
    "qtastacked": 0,
    "qtacont": 0,
    "consumo":0,
    "riordino":0,
    "idwar":1,
    "idSparePart":0


  }
};

const slice = createSlice({
  name: 'element',
  initialState,
  reducers: {
    getMachineElement(state, action) {
      const { element } = action.payload;

      state.element = element;
    },

    getMachineElement(state, action) {
      state.status = 'getMachineElement';
      
      
      state.element =   {
        "elementId": action.payload.id,
        "elementIdMachine": action.payload.idMachine,
        "elementIdFather": action.payload.idFather,
        "elementIdtipology": action.payload.idtipology, 
        "elementTiporec": action.payload.tiporec, 
        "elementSpare": action.payload.spare, 
        "elementCode": action.payload.code, 
        "elementCodeSap": action.payload.codeSap, 
        "elementCodeSpare": (action.payload.macSpare == null ? "" : action.payload.macSpare.codeSpare),   
        "elementDescription": action.payload.description, 
        "elementIdsupplier":action.payload.idsupplier,
        "elementFlagAm": action.payload.flagAm, 
        "elementDateSetup": (action.payload.dateSetup  == null ? "" : moment(action.payload.dateSetup).format('MM/DD/YYYY')), 
        "elementPrice": (action.payload.macSpare  == null ? 0: action.payload.macSpare.warSparepart == null ? 0 : action.payload.macSpare.warSparepart.costOrder), 
        "elementClassSpare": (action.payload.macSpare == null ? "" : action.payload.macSpare.classSpare),         
        "elementNrBd": (action.payload.macSpare == null ? "": action.payload.macSpare.nrBd),
        "elementminBd": (action.payload.macSpare == null ? "": action.payload.macSpare.minBd),
        "elementMtbfT": (action.payload.macSpare == null ? "": action.payload.macSpare.mtbfT),
        "elementMtbfR": (action.payload.macSpare == null ? "": action.payload.macSpare.mtbfR),
        "elementMttrT": (action.payload.macSpare == null ? "": action.payload.macSpare.mttrT),
        "elementMttrR": (action.payload.macSpare == null ? "": action.payload.macSpare.mttrR),
        "elementPosition": (action.payload.macSpare   == null ?  "" : action.payload.macSpare.warSparepart == null ? "" : action.payload.macSpare.warSparepart.position),
        //"elementPosition": (action.payload.macSpare   == null ?  "" : action.payload.macSpare.warSparepart.position || "" ),
        "elementStockMin": (action.payload.macSpare  == null ? 0: action.payload.macSpare.warSparepart == null ? 0 : action.payload.macSpare.warSparepart.optimalStock), 
        "elementStock": (action.payload.macSpare == null ? 0: action.payload.macSpare.warSparepart == null ? 0 : action.payload.macSpare.warSparepart.currentStock), 
        "elementDimRic": (action.payload.macWarehouses== null ? "": action.payload.dimRic), 
        "elementDimCont": (action.payload.macWarehouses== null ? "": action.payload.macWarehouses.dimCont), 
        "elementStacked": (action.payload.macWarehouses== null ? 0: action.payload.macWarehouses.stacked), 
        "elementQtastacked": (action.payload.macWarehouses== null ? 0: action.payload.macWarehouses.qtastacked), 
        "elementQtacont": (action.payload.macWarehouses== null ? 0: action.payload.macWarehouses.qtacont),  
        "elementConsumo": (action.payload.macWarehouses== null ? 0: action.payload.macWarehouses.consumo),  
        "elementRiordino": (action.payload.macSpare   == null ?  "" : action.payload.macSpare.warSparepart == null ? "" : action.payload.macSpare.warSparepart.currentReorderLevel),
        "elementIdSparePart": (action.payload.macSpare== null ?  0 : action.payload.macSpare.warSparepart == null ? 0 : action.payload.macSpare.idSparePart),
        "elementIdWar": (action.payload.macSpare== null ?  0 : action.payload.macSpare.warSparepart == null ? 0 :  action.payload.macSpare.idWar),
        "elementImage": action.payload.fileImg

      }
    },
    updateMachine(state, action) {
     
      
      state.status = 'updateMachine'; 
      state.element.elementId = action.payload.id || state.element.elementId;
      state.element.elementIdMachine = action.payload.idMachine ;
      state.element.elementIdFather = action.payload.idFather;
      state.element.elementIdtipology = action.payload.idtipology|| state.element.elementIdtipology;
      state.element.elementTiporec = action.payload.tiporec|| state.element.elementTiporec;
      state.element.elementSpare= action.payload.spare|| state.element.elementSpare;
      state.element.elementCode = action.payload.code || state.element.elementCode;
      state.element.elementDescription = action.payload.description || state.element.elementDescription;
      state.element.elementIdsupplier = action.payload.idsupplier || state.element.elementIdsupplier;
      state.element.elementCodeSap = action.payload.codeSap || state.element.elementCodeSap;
      state.element.elementDateSetup = action.payload.dateSetup || state.element.elementDateSetup;
      state.element.elementClassSpare = action.payload.classSpare || state.element.elementClassSpare;
      state.element.elementCodeSpare = action.payload.codeSpare || state.element.elementCodeSpare;
      // gestione legame con spare e warehouse
      state.element.elementIdSparePart = action.payload.idSparePart || state.element.elementIdSparePart;
      state.element.elementIdWar = action.payload.idWar || state.element.elementIdWar;
      // aggingere flag ricondizionabile
     
      state.element.elementPrice = action.payload.price || state.element.elementPrice;
      state.element.elementNrBd = action.payload.nrBd || state.element.elementNrBd;
      state.element.elementMinBd = action.payload.minBd || state.element.elementMinBd;

      //warehouse
      state.element.elementPosition = action.payload.position || state.element.elementPosition;
      state.element.elementConsumo = action.payload.consumo || state.element.elementConsumo;
      state.element.elementStock = action.payload.stock || state.element.elementStock;
      state.element.elementRiordino = action.payload.riordino || state.element.elementRiordino;
      state.element.elementStockMin = action.payload.stockMin || state.element.elementStockMin;
      state.element.elementDimRic = action.payload.dimRic || state.element.elementDimRic;
      state.element.elementDimCont = action.payload.dimCont || state.element.elementDimCont;
      state.element.elementStacked = action.payload.stacked || state.element.elementStacked;
      state.element.elementQtastacked = action.payload.qtastacked || state.element.elementQtastacked;
      state.element.elementQtacont = action.payload.qtacont || state.element.elementQtacont;
      state.element.elementMtbfT = action.payload.mtbfT || state.element.elementMtbfT;
      state.element.elementMtbfR = action.payload.mtbfR || state.element.elementMtbfR;
      state.element.elementMttrT = action.payload.mttrT || state.element.elementMttrT;
      state.element.elementMttrR = action.payload.mttrR || state.element.elementMttrR;      
      
      
      
      



      // gestire immagine state.element.fileImg = action.payload.fileImg|| state.machine.fileImg;
    },
    putMachine(state, action) {
      state.status = 'putMachine'; 
    }
  }
});
export const reducer = slice.reducer;

/* export const getMachineElement = () => async (dispatch) => {
  const response = await axios.get('/machinestructure');
  dispatch(slice.actions.getMachineElement(response.data));
}; */

export const getMachineElement = (id) => async (dispatch) => {
  const response = await axios.get("/machinestructure/items/" + id);
  dispatch(slice.actions.getMachineElement( response.data )); 
};

export const updateMachine = (update) => async (dispatch) => {
  
  dispatch(slice.actions.updateMachine( update )); 
};



export const putMachine = (id, idtipology, tiporec, spare, code, sap, description, idSupplier,  datesetup, classSpare, codeSpare, price,  mttrT, mtbfR, position, stockMin,Stock, dimRic,dimCont,stacked, qtastacked, qtacont, consumo, riordino,  idWarSparepart, idwar) => async (dispatch) => {
  //let axios1 = require('axios'); 
  
  // axios1.defaults.baseURL = 'http://myurl';
//  axios.defaults.crossDomain = true;
 // axios1.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
 // axios1.defaults.headers.put["accept"] = "*/*";
//  axios1.defaults.headers.put["Content-Type"] = "application/json-patch+json";
  const response = await axios.put("/machinestructure/items", 

  {
    id: id,
    //idMachine: 0,
    //idFather: 0,
    idtipology: idtipology,
    tiporec: tiporec,
    spare: spare == 1 ? true : false,
    code: code,
    codeSap: sap || codeSpare,
    description: description,
    idsupplier: parseInt(idSupplier),    
    dateSetup: datesetup,
    classSpare: classSpare,
    codeSpare: codeSpare,
    price: price || 0,
    
    mtbfR: mtbfR,
    mttrT: mttrT,
    
    position: position,
    stockMin: stockMin,
    stock: Stock,
    dimRic: dimRic,
    dimCont: dimCont,
    stacked: stacked,
    qtastacked: qtastacked,
    qtacont: qtacont,
    consumo: consumo,
    riordino: riordino,
    mttrR: "",
    mtbfT: mttrT,
    nrBd: 0,
    minBd: 0,
    flagAm: false,
    idwar: idwar,
    idWarSparepart: idWarSparepart
  })
    
   //"{\"id\":" +id+",\"idtipology\":"+idtipology+",\"tiporec\":\""+tiporec+"\",\"spare\":"+spare+",\"code\":\""+code+"\",\"codeSap\":\""+sap+"\",\"description\":\""+description+"\",\"idsupplier\":"+idSupplier+",\"flagAm\":false,\"dateSetup\":\""+datesetup+"\",\"classSpare\":\""+classSpare+"\",\"codeSpare\":\""+codeSpare+"\",\"price\":\""+price+"\",\"nrBd\":0,\"minBd\":0,\"mtbfT\":\"\",\"mtbfR\":\""+mtbfR+"\",\"mttrT\":\"\",\"mttrT\":\""+mttrT+"\",\"position\":\""+position+"\",\"stockMin\":"+stockMin+",\"stock\":"+Stock+",\"dimRic\":\""+dimRic+"\",\"dimCont\":\""+dimCont+"\",\"stacked\":"+stacked+",\"qtastacked\":"+qtastacked+",\"qtacont\":"+qtacont+",\"consumo\":"+consumo+",\"riordino\":\""+riordino+"\"}");
  
    dispatch(slice.actions.putMachine(response.data)); 
};

export const postMachine = (id, idtipology, tiporec, spare, code, sap, description, idSupplier,  datesetup, classSpare, codeSpare, price, mtbfR, mttrT, position, stockMin,Stock, dimRic,dimCont,stacked, qtastacked, qtacont, consumo, riordino) => async (dispatch) => {
  let axios1 = require('axios');
  
  // axios1.defaults.baseURL = 'http://myurl';
  //axios1.defaults.crossDomain = true;
 // axios1.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
 // axios1.defaults.headers.put["accept"] = "*/*";
 // axios1.defaults.headers.put["Content-Type"] = "application/json-patch+json";
  const response = await axios.post("/machinestructure/items", 
  {
    id: id,
    //idMachine: 0,
    //idFather: 0,
    idtipology: idtipology,
    tiporec: tiporec,
    spare: spare == 1 ? true : false,
    code: code,
    codeSap: sap,
    description: description,
    idsupplier: idSupplier,    
    dateSetup: datesetup,
    classSpare: classSpare,
    codeSpare: codeSpare,
    price: price,
    
    mtbfR: mtbfR,
    mttrT: mttrT,
    
    position: position,
    stockMin: stockMin,
    stock: Stock,
    dimRic: dimRic,
    dimCont: dimCont,
    stacked: stacked,
    qtastacked: qtastacked,
    qtacont: qtacont,
    consumo: consumo,
    riordino: riordino,
    mttrR: "",
    mtbfT: "",
    nrBd: 0,
    minBd: 0,
    flagAm: false
  })
    
 //  "{\"id\":" +id+",\"idtipology\":"+idtipology+",\"tiporec\":\""+tiporec+"\",\"spare\":"+spare+",\"code\":\""+code+"\",\"codeSap\":\""+sap+"\",\"description\":\""+description+"\",\"idsupplier\":"+idSupplier+",\"flagAm\":false,\"dateSetup\":\""+datesetup+"\",\"classSpare\":\""+classSpare+"\",\"codeSpare\":\""+codeSpare+"\",\"price\":\""+price+"\",\"nrBd\":0,\"minBd\":0,\"mtbfT\":\"\",\"mtbfR\":\""+mtbfR+"\",\"mttrT\":\"\",\"mttrT\":\""+mttrT+"\",\"position\":\""+position+"\",\"stockMin\":"+stockMin+",\"stock\":"+Stock+",\"dimRic\":\""+dimRic+"\",\"dimCont\":\""+dimCont+"\",\"stacked\":"+stacked+",\"qtastacked\":"+qtastacked+",\"qtacont\":"+qtacont+",\"consumo\":"+consumo+",\"riordino\":\""+riordino+"\"}");
  
    dispatch(slice.actions.putMachine(response.data)); 
};

export default slice;


