import axios from 'src/utils/terotechDataservice';
import jwt from 'jsonwebtoken';



//SMP
function getInfoUser(){
  return  axios.get("/user/get");
}

function getPlants(){
  return  axios.get("/user/items");
}
function getPlantsChoice() {
  return axios.get(`/plantTenant/items`);
}
function putDefaultPlant(data){
  const options = {
    headers: {'Content-Type': 'application/json'}
  };
  return  axios.put("/user/defaultPlant",data,options);
}

export {
  getInfoUser,
  getPlants,
  putDefaultPlant,
  getPlantsChoice
}
