import React, {
  useState,
  useCallback,
  useRef
} from 'react';
import { capitalCase } from 'change-case';
import useAuth from 'src/hooks/useAuth';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES, LANG, GLOB, FORM} from 'src/constants';
import i18n from 'src/i18n';
import axios from 'src/utils/terotechDataservice';
import * as dataSetting from 'src/dataservice/dataSetting';
import data from 'src/views/release/Release.json';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [items, setItems] = useState(data);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const { user } = useAuth();
  console.log('settings', settings)
  const [values, setValues] = useState({
    direction: settings ? settings.direction : "ltr",
    responsiveFontSizes: settings ? settings.responsiveFontSizes : true,
    theme: settings ? settings.theme : "TEROTECH",
    language: settings ? settings.language : "en",
    globalization: settings ? settings.globalization : "english"
  });

  useEffect(() => {
    if (settings) {
      setValues({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
        language: settings.language,
        globalization: settings.globalization
      })
    }
    else {
      setValues({
        direction:"ltr",
        responsiveFontSizes: true,
        theme: "TEROTECH",
        language:"en",
        globalization: "english"
      })
    }
  }, [settings]);


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = useCallback(async () => {
    try {
      var data = {
        //idUser: user.id,
        settings: JSON.stringify(values)
      }

      await dataSetting.put(data)
      saveSettings(values);

      setOpen(false);
      window.location.reload();

    } catch (err) {
      console.error(err);
    }
  });

  const openRelease = () =>{
    window.open('/release', '_blank');
    //history.push('/app/release');
  }

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Settings
        </Typography>
        <Box
          mt={2}
          px={1}
        >
{/*           <FormControlLabel
            control={(
              <Switch
                checked={values.direction === 'rtl'}
                edge="start"
                name="direction"
                onChange={(event) => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
              />
            )}
            label="RTL"
          />
        </Box>
        <Box
          mt={2}
          px={1}
        > */}
          <FormControlLabel
            control={(
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
              />
            )}
            label="Responsive font sizes"
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(THEMES[theme])}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Language"
            name="language"
            onChange={(event) => {handleChange('language', event.target.value); i18n.changeLanguage(event.target.value);    localStorage.setItem('i18nextLng', event.target.value);}}
            select
            SelectProps={{ native: true }}
            value={values.language}
            defaultValue={values.language}
            variant="outlined"
          >
            {Object.keys(LANG).map((language) => (
              <option
                key={language}
                value={language}
              >
                {capitalCase(LANG[language])}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Regional Settings"
            name="Regional Settings"
            onChange={(event) => {handleChange('globalization', event.target.value); /*i18n.changeLanguage(event.target.value);  */ }}
            select
            SelectProps={{ native: true }}
            value={values.globalization}
            variant="outlined"
          >
            {Object.keys(GLOB).map((globalization) => (
              <option
                key={globalization}
                value={globalization}
              >
                {capitalCase(GLOB[globalization])}
              </option>
            ))}
          </TextField>
        </Box>
        
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle2" color="textSecondary" onClick={openRelease}>
            Versione {items[0].version} - {items[0].date} 
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
